/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

// Array of sections
export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    route: 'dashboard',
    permissions: ['dashboard'],
  },
  {
    header: 'Pages Management',
    permissions: ['pages'],
  },
  {
    title: 'Pages',
    icon: 'BookOpenIcon',
    route: 'pages',
    permissions: ['pages'],
  },
  {
    header: 'Sliders Management',
    permissions: ['sliders'],
  },
  {
    title: 'Media Center',
    icon: 'ImageIcon',
    route: 'albums',
    permissions: ['albums'],
  },
  {
    title: 'Sliders',
    icon: 'MonitorIcon',
    route: 'sliders',
    permissions: ['sliders'],
  },
  {
    title: 'Categories',
    icon: 'FolderIcon',
    route: 'categories',
    permissions: ['categories'],
  },
  {
    title: 'Testimonials',
    icon: 'MonitorIcon',
    route: 'testimonials',
    permissions: ['testimonials'],
  },
  {
    title: 'Features',
    icon: 'MonitorIcon',
    route: 'features',
    permissions: ['features'],
  },
  {
    title: 'Teams',
    icon: 'CoffeeIcon',
    route: 'teams',
    permissions: ['teams'],
  },
  {
    title: 'News',
    icon: 'FileTextIcon',
    route: 'news',
    permissions: ['news'],
  },
  {
    title: 'Objectives',
    icon: 'FileTextIcon',
    route: 'objectives',
    permissions: ['objectives'],
  },
  {
    title: 'Partners',
    icon: 'UsersIcon',
    route: 'partners',
    permissions: ['partners'],
  },
  {
    header: 'Roles & Users',
    permissions: ['users', 'roles'],
  },
  {
    title: 'Roles',
    icon: 'UsersIcon',
    route: 'roles',
    permissions: ['roles'],
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    route: 'users',
    permissions: ['users'],
  },
  {
    header: 'Requests Management',
    permissions: ['contact-us'],
  },
  {
    title: 'Contact Us',
    icon: 'PhoneIcon',
    route: 'contactus',
    permissions: ['contact-us'],
  },
  {
    header: 'Settings',
    permissions: ['settings'],
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    route: 'settings',
    permissions: ['settings'],
  },
]
