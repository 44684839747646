var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-menu menu-fixed menu-accordion menu-shadow",
    class: [{
      'expanded': !_vm.isVerticalMenuCollapsed || _vm.isVerticalMenuCollapsed && _vm.isMouseHovered
    }, _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light'],
    on: {
      "mouseenter": function mouseenter($event) {
        return _vm.updateMouseHovered(true);
      },
      "mouseleave": function mouseleave($event) {
        return _vm.updateMouseHovered(false);
      }
    }
  }, [_c('div', {
    staticClass: "navbar-header expanded"
  }, [_vm._t("header", function () {
    return [_c('ul', {
      staticClass: "nav navbar-nav flex-row"
    }, [_c('li', {
      staticClass: "nav-item mr-auto"
    }, [_c('b-link', {
      staticClass: "navbar-brand",
      attrs: {
        "to": "/"
      }
    }, [_c('span', {
      staticClass: "brand-logo"
    }, [_c('b-img', {
      staticClass: "logo-nav",
      attrs: {
        "src": _vm.appLogoImage,
        "alt": "logo"
      }
    })], 1), _c('h2', {
      staticClass: "brand-text"
    }, [_vm._v(" " + _vm._s(_vm.appName) + " ")])])], 1), _c('li', {
      staticClass: "nav-item nav-toggle"
    }, [_c('b-link', {
      staticClass: "nav-link modern-nav-toggle"
    }, [_c('feather-icon', {
      staticClass: "d-block d-xl-none",
      attrs: {
        "icon": "XIcon",
        "size": "20"
      },
      on: {
        "click": _vm.toggleVerticalMenuActive
      }
    }), _c('feather-icon', {
      staticClass: "d-none d-xl-block collapse-toggle-icon",
      attrs: {
        "icon": _vm.collapseTogglerIconFeather,
        "size": "20"
      },
      on: {
        "click": _vm.toggleCollapsed
      }
    })], 1)], 1)])];
  }, {
    "toggleVerticalMenuActive": _vm.toggleVerticalMenuActive,
    "toggleCollapsed": _vm.toggleCollapsed,
    "collapseTogglerIcon": _vm.collapseTogglerIcon
  })], 2), _c('div', {
    staticClass: "shadow-bottom",
    class: {
      'd-block': _vm.shallShadowBottom
    }
  }), _c('vue-perfect-scrollbar', {
    staticClass: "main-menu-content scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings,
      "tagname": "ul"
    },
    on: {
      "ps-scroll-y": function psScrollY(evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
      }
    }
  }, [_c('vertical-nav-menu-items', {
    staticClass: "navigation navigation-main",
    attrs: {
      "items": _vm.menuRender
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }